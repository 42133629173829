<template>
    <p>Через <span v-if="days >0">{{days}} {{days == 1 ? "день" : "дней"}}&nbsp;</span>{{ twoDigits(hours) }}ч</p>
</template>

<script>
    let interval = null;

    export default {
        name: "KCountdown",
        props: {
            minutesLeft: {
                type: Number,
                default: 0
            },
            stop: {
                type: Boolean
            }
        },
        data() {
            return {
                now: Math.trunc((new Date()).getTime() / 1000),
                date: null,
                diff: 0
            }
        },
        created() {

            let endTime = this.dateAdd(new Date(), "minute", this.minutesLeft);
            this.date = Math.trunc(Date.parse((endTime + "").replace(/-/g, "/")) / 1000);

            if (!this.date) {
                throw new Error("Invalid props value, correct the 'deadline' or 'end'");
            }
            this.now = Math.trunc((new Date()).getTime() / 1000);
            interval = setInterval(() => {
                this.now = Math.trunc((new Date()).getTime() / 1000);
            }, 1000);
        },
        computed: {
            seconds() {
                return Math.trunc(this.diff) % 60
            },

            minutes() {
                return Math.trunc(this.diff / 60) % 60
            },

            hours() {
                return Math.trunc(this.diff / 60 / 60) % 24
            },

            days() {
                return Math.trunc(this.diff / 60 / 60 / 24)
            }
        },
        watch: {
            now() {
                this.diff = this.date - this.now;
                if (this.diff <= 0 || this.stop) {
                    this.diff = 0;
                    // Remove interval
                    clearInterval(interval);
                }
            }
        },
        methods: {
            dateAdd(date, interval, units) {
                if (!(date instanceof Date))
                    return undefined;
                var ret = new Date(date); //don't change original date
                var checkRollover = function () {
                    if (ret.getDate() != date.getDate()) ret.setDate(0);
                };
                switch (String(interval).toLowerCase()) {
                    case 'year'   :
                        ret.setFullYear(ret.getFullYear() + units);
                        checkRollover();
                        break;
                    case 'quarter':
                        ret.setMonth(ret.getMonth() + 3 * units);
                        checkRollover();
                        break;
                    case 'month'  :
                        ret.setMonth(ret.getMonth() + units);
                        checkRollover();
                        break;
                    case 'week'   :
                        ret.setDate(ret.getDate() + 7 * units);
                        break;
                    case 'day'    :
                        ret.setDate(ret.getDate() + units);
                        break;
                    case 'hour'   :
                        ret.setTime(ret.getTime() + units * 3600000);
                        break;
                    case 'minute' :
                        ret.setTime(ret.getTime() + units * 60000);
                        break;
                    case 'second' :
                        ret.setTime(ret.getTime() + units * 1000);
                        break;
                    default       :
                        ret = undefined;
                        break;
                }
                return ret;
            },
            twoDigits(value) {
                if (value.toString().length <= 1) {
                    return '0' + value.toString()
                }
                return value.toString()
            }
        },
        unmounted() {
            clearInterval(interval);
        }
    }
</script>

<style scoped>

</style>