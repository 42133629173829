<template>
    <div v-if="isVisible" class="MBpay" :class='$mq'>
        <p class='header2'>{{$t("message.title")}}</p>
        <div class="button-block" :style="isFinalStep?'justify-content:center;':''">
            <a class='download-bt half' @click="tryToPay('left_full')">{{$t("button.pay_full")}} {{sum}} ₽</a>
            <p v-if="!isFinalStep">{{$t("message.or")}}</p>
            <a v-if="!isFinalStep" class='download-bt half fade' @click="tryToPay('left_half')">{{$t("button.pay_part")}} {{getSumHalf}} ₽</a>
        </div>
    </div>
</template>

<script>
    import {createOrder} from "../../service/api";

    export default {
        name: "KPaymentWidget",
        props: {
            sum: Number,
            step: Number,
            courseId: {
              type: String,
              default: "0"
            },
            chapterStep: Number
        },
        computed: {
            isVisible () {
                return this.step>0 && this.chapterStep <= this.step
            },
            getSumHalf () {
                return Math.ceil(this.sum / 2)
            },
            isFinalStep() {
                return this.step == 1
            }
        },
        methods: {
            tryToPay(type) {
                createOrder(this.courseId, type)
                    .then(response => {
                        let orderId = response.data.data.order_id
                        this.$router.push('/order/'+orderId)
                    })
            }
        },
        i18n: {
            messages: {
                en: {
                    message: {
                        or : "or",
                        title: "Please pay :)"
                    },
                    button: {
                        pay_full: "Pay all" ,
                        pay_part: "Pay part" ,
                    }
                },
                ru: {
                    message: {
                        or : "или",
                        title: "Пожалуйста, внесите оплату :)"
                    },
                    button: {
                        pay_full: "Оплатить остаток" ,
                        pay_part: "Оплатить часть" ,
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>