<template>
  <div v-if="loadingState === 'loading'" :class="loaderClass">
    <p>Загрузка ...</p>
  </div>
  <div v-if="loadingState === 'success'" class="MBcont" :class='$mq'>

      <h1>{{ title }}</h1>
      <slot name="subtitle"></slot>

      <splide v-if="items.length>0" :slides="items" :options="options">
        <splide-slide v-for="(slide, index) in items" :key="slide.src">
          <a @click="openCourse(index)" :class="getSliderClasses(index)">
            <div>
              <div>{{ getStep(index) }}-{{ getSuffix(getStep(index)) }} шаг</div>
              <div class="slide-h" :class='$mq'>{{ slide.title }}</div>
            </div>
            <a v-if="!isDisabled(index)" class="slide-look">Смотреть &#8250;</a>
            <div v-else-if="isTimerVisible(index)">
              <KCountdown :minutes-left="getMinutesLeft(items[index].chapter_id)"/>
            </div>
            <p v-else-if="isPaymentNeeded(index)">
              Оплатите для просмотра
            </p>
          </a>
        </splide-slide>
      </splide>

    <div class='MBinside' v-if="true">
      <component :is="paymentComponent"/>
    </div>

    <div class='MBinside' v-else>      
      <KPaymentWidget  v-if="items.length>0" :step="paymentStep" :sum="paymentSum" :course-id="courseId"
                      :chapter-step="items[getCurrentIndex()].payment_step"/>

      <div v-if="items.length>0" class="MBtextcont">
        <h2>{{ getSubTitle() }}</h2>
      </div>
      <slot></slot>
      <div class="MBpagenav" :class="$mq">
        <a :class="getPrevButtonClasses" @click="goPrev">&#8249; Предыдущая</a>
        <a v-if="!isDisabled(getCurrentIndex()+1)" :class="getNextButtonClasses" @click="goNext">Следующая &#8250;</a>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, watch, watchEffect} from "vue";
import {loadChapters, loadVideo} from "../../service/api";
import {useRouter, useRoute} from "vue-router";
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import KCountdown from "./KCountdown";
import KPaymentWidget from "./KPaymentWidget";

export default {
  name: "KCourse",
  components: {
    Splide,
    SplideSlide,
    KCountdown,
    KPaymentWidget
  },
  data() {
    return {
      observer: null,
      options: {
        rewind: false,
        perPage: 4,
        gap: '0.3rem',
        pagination: false,
        cover: true,
        start: this.getCurrentIndex(),
        breakpoints: {
          700: {
            perPage: 2,
          },
          1000: {
            perPage: 3,
          },
        },
      },
    };
  },
  props: {
    loaderClass: String,
    contentClass: String,
    courseId: {
      type: String,
      default: "0"
    },
    chapterId: {
      type: Number,
      default: 0
    },
    title: String,
    items: Array,
    chapterPayment: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const loadingState = ref("loading");
    const otp = ref(null)
    const lockedChapterIds = ref([])
    const minutesLeft = ref([])
    const paymentStep = ref(0)
    const paymentSum = ref(0)
    const needsPayment = ref(false)
    const paymentComponent = ref("")

    import("@/components"+props.chapterPayment).then(val => {
      paymentComponent.value = val.default;
    });

    const fetchCourses = () => {
      loadingState.value = "loading";
      paymentStep.value = 0;
      paymentSum.value = 0;

      return loadChapters()
        .then((response) => {
          loadingState.value = "success";
          if (props.courseId == 0) {
            let id = route.path.substring(route.path.lastIndexOf('/') + 1) - 1
            if (id && props.items[id] && props.items[id].state == 'disabled') {
              router.push("/403");
            }
          } else {
            var items = [];
            response.data.data.forEach((item) => {

              if (!items.includes(item["course_id"] + "")) {
                items.push(item["course_id"] + "")
              }
              let chapterId = parseInt(item["chapter_id"])
              if (chapterId != 0 && parseInt(item["locked_minutes"]) > 0) {
                lockedChapterIds.value.push(chapterId)
                minutesLeft.value[chapterId] = parseInt(item["locked_minutes"])
              }
              if (item['course_id'] == props.courseId) {
                paymentStep.value = parseInt(item["payment_step"])
                paymentSum.value = item["payment_left"]
                if (item['locked'] == 1) {
                  router.push("/403");
                }
              }
              if (chapterId == props.chapterId && item["needs_payment"] == 1) {
                needsPayment.value = true
              }
            });


            let id = route.path.substring(route.path.lastIndexOf('/') + 1) - 1

            if (lockedChapterIds.value.includes(props.items[id].chapter_id)) {
              router.push("/403");
            }
            if (!items.includes(props.courseId)) {
              router.push("/403");
            }
            if (props.items[id].payment_step < paymentStep.value) {
              router.push("/403");
            }
            if (route.query.videopopup) {
              loadOtp(route.query.videopopup)
            }
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 404) {
            router.push("/404");
          }
          loadingState.value = "error";
        });
    };
    const loadOtp = (videoId) => {

      return loadVideo(videoId)
        .then(response => {
          otp.value = response.data.data.otp
        })
        .catch(error => {
          //todo add error view
          console.log(error)
          if (error.response.status === 404) {
            router.push("/404")
          }
        })
    }

    return {paymentComponent, loadingState, fetchCourses, otp, lockedChapterIds, minutesLeft, paymentSum, paymentStep, needsPayment};
  },
  created() {

    this.fetchCourses();
    watchEffect(() => {
      if (this.otp === null || this.otp === undefined) {
        return
      }
      let videoWidth = window.innerWidth < 800 ? window.innerWidth : 800
      let videoId = this.$route.query.videopopup
      this.$swal.fire({
        width: videoWidth + 'px',
        background: '#eae2e0',
        html: '<div id="video' + videoId + '"></div>',
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: "",
        didOpen: () => {
          new VdoPlayer({ // eslint-disable-line
            otp: this.otp,
            playbackInfo: btoa(JSON.stringify({
              videoId: videoId
            })),
            theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
            // the container can be any DOM element on website
            container: document.querySelector("#video" + videoId),
          });
        },
        didDestroy: () => {
          this.otp = null
        }
      })
    })
    // watchEffect(() => {
    //     if
    // })
    const options = {
      // родитель целевого элемента - область просмотра
      root: null,
      // без отступов
      rootMargin: '200px',
      // процент пересечения - половина изображения
      threshold: 0
    }
    // создаем наблюдатель
    this.observer = new IntersectionObserver((entries, observer) => {
      // для каждой записи-целевого элемента
      entries.forEach(entry => {
        let newSrc = entry.target.getAttribute("data-src")
        // console.log(entry)
        // если элемент является наблюдаемым
        if (entry.isIntersecting && entry.target.src === "" && newSrc.indexOf("vdociper") >= 0) {
          const frame = entry.target
          frame.src = newSrc
          // observer.unobserve(frame)
        }
        if (entry.intersectionRatio === 0 && entry.target.src !== "") {
          observer.unobserve(entry.target)
          let element = '<iframe scrolling="no" id="' + entry.target.id + '" width=100% height=100% data-src="' + entry.target.src + '"></iframe>'
          let frameParent = document.getElementById("parent-" + entry.target.id);
          frameParent.innerHTML = element;


          var iframe = document.getElementById(entry.target.id);

          // Adjusting the iframe height onload event
          iframe.onload = function () {
            iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 10 + 'px';
          }
          window.observer.observe(iframe)
        }
      })
    }, options)
    window.observer = this.observer
  },
  unmounted() { /* Options API */
    this.observer = null
  },
  computed: {
    getPrevButtonClasses() {
      return {
        'MBprev': true,
        'button-hidden': 0 == this.getCurrentIndex(),
        'full': this.items.length - 1 == this.getCurrentIndex()
      }
    },
    getNextButtonClasses() {
      return {
        'MBnext': true,
        'button-hidden': this.items.length - 1 == this.getCurrentIndex(),
        'full': 0 == this.getCurrentIndex()
      }
    },
  },
  methods: {
    getStep(index) {
      var step = index + 1
      for (var i = index; i >= 0; i--) {
        if (this.items[i].state == 'payment') {
          step--;
        }
      }
      return step
    },
    getMinutesLeft(chapterId) {
      return this.minutesLeft[chapterId]
    },
    goNext() {
      this.$router.push(this.items[this.getCurrentIndex() + 1].url)
    },
    goPrev() {
      this.$router.push(this.items[this.getCurrentIndex() - 1].url)
    },
    openCourse(index) {
      if (!this.isDisabled(index) && !this.lockedChapterIds.includes(this.items[index].chapter_id)) {
        this.$router.push(this.items[index].url)
      }
    },
    getLastPathSegment() {
      let path = this.$route.path
      return path.substring(path.lastIndexOf('/') + 1)
    },
    getSliderClasses(index) {
      return {
        'need-payment': this.isPaymentNeeded(index),
        'slide-item': true,
        'selected': (index) == this.getCurrentIndex(),
        'disabled': this.isDisabled(index),
        'active': !this.isDisabled(index),
      }
    },
    isPaymentNeeded(index) {
      return this.items[index].payment_step < this.paymentStep
    },
    isDisabled(index) {
      if (this.items.length === 0) {
        return false
      }
      if (index >= this.items.length) {
        return true
      }
      if (this.items[index].payment_step < this.paymentStep) {
        return true
      }
      return (this.items[index].state == 'disabled' || this.lockedChapterIds.includes(this.items[index].chapter_id))
    },
    isTimerVisible(index) {
      if (this.items[index].payment_step < this.paymentStep) {
        return false
      }
      return this.items[index].state != 'disabled'
    },
    getCurrentIndex() {
      return this.getLastPathSegment() - 1
    },
    getSubTitle() {
      let index = this.getCurrentIndex()
      let item = this.items[index]
      let title = item ? item.title : ""
      return (index + 1) + '-' + this.getSuffix(index + 1) + ' шаг. ' + title
    },
    getSuffix(number) {
      if (number >= 10 && number <= 20) {
        return 'ый'
      }
      let lastOne = +number.toString().split('').pop();
      switch (lastOne) {
        case 0:
        case 1:
        case 4:
        case 5:
        case 9:
          return 'ый'
        case 2:
        case 6:
        case 7:
        case 8:
          return 'ой'
        case 3:
          return 'ий'
      }

    }
  }
}
</script>

<style>

.MBtextcont .MBbutton {
  padding: 1em 1em;
  margin-bottom: 3em;
}
  .MBtextcont .MBbutton.mobile {
    font-size: 1em;
    font-weight: 600;
  }


.MBinside {
  padding-top: 2em;
}

.container {
  position: relative;
  width: 100%;
  /* padding-bottom: 56.25%; */
  box-sizing: border-box;
  margin-bottom: 1em;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.MBcont p.small-text {
  font-size: 0.8em;
  text-align: center;
  position: relative;
  top: -0.8em;
}

.MBcont.mobile p.small-text {
  text-align: left;
  top: 0;
}

.MBtextcont ul.nomargin {
  margin-bottom: 0;
}

.slide-item.need-payment {
  background: #4d4d4d;
  color: white;
}

.need-payment a {
  color: white;
}

.need-payment.disabled::after {
  display: none;
}

.slide-item.need-payment:hover {
  background-color: #4d4d4d;
  color: white;
}

.summary {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin-bottom: 2em;
}

.checklist {
  background-color: #ef8d7f;
  padding: 1.5em 2em 1.7em 2em;
  color: white;
  display: flex;
  justify-content: space-between;
  margin: 3em 0;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
}

.checklist.mobile {
  padding: 1em;
  font-size: 0.8em;
}

a.checklist::after {
  border: 0px solid transparent !important;
}

a.checklist:hover {
  transform: scale(1.1);
}

.checklist-text {
  width: 70%;
}

.checklist-text.mobile {
  width: 70%;
}

a.checklist p {
  margin: 0;
}

a.checklist h3{
  line-height: 1em;
  margin-bottom: 0.5em;
}

a.checklist.mobile h3 {
  font-size: 1.4em;
  line-height: 0.9em;
  margin-bottom: 0.5em;
}

.checklist p {
  font-size: 0.9em;
  line-height: 1em;
}

.checklist-img {
  position: relative;
  width: 30%;
}

.checklist-img.mobile {
  width: 100px;
  height: 50px;
}

.checklist-icon {
  width: 144px;
  position: absolute;
  top: -3.5em;
}

.checklist-img.halftablet .checklist-icon {
  left: -2.5em;
}

.checklist-img.mobile .checklist-icon {
  width: 100px;
  left: -1.5em;
  top: -2em;
}

b {
  font-weight: 600;
}

i {
  font-style: italic;
}

.MBpay {
  font-size: 0.8em;
  background-color: #eaeaea;
  padding: 1em 2em;
  border-radius: 5px;
  margin: 2em auto 0 auto;
  max-width: 600px;
}

.MBpay b {
  font-weight: 600;
}

p.header2 {
  font-size: 2em;
  text-align: center;
}

.MBpay.halftablet p.header2, .MBpay.mobile p.header2 {
  font-size: 1.5em;
  line-height: 1em;
}

a.disabled {
  background-color: #eaeaea;
  color: #333;
  position: relative;
  cursor: none;
}

a.disabled:hover {
  background-color: #eaeaea;
  color: #333;
  position: relative;
  transform: scale(1);
}

a.disabled::after {
  content: url("~@/assets/membersh/lock.svg");
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 15px;
  right: 10px;

}

a.disabled a {
  color: #333;
}

li.splide__slide {
  margin: 0;
}

.MBpagenav {
  display: flex;
  justify-content: space-between;
}

.MBpagenav a {
  display: block;
  width: 50%;
}

.MBpagenav.mobile a {
  font-size: 0.8em;
}

.MBpagenav a.MBprev {
  text-align: left;
}

.MBpagenav a.MBnext {
  text-align: right;
}

button:disabled, a.button-hidden {
  display: none;
}

.MBpagenav a.full {
  width: 100%;
}

.splide__track {
  padding: 10px 0;
}

.splide__arrow {
  background-color: white;
  border: 1px solid #e6e6e6;
  opacity: 1;
  width: 45px;
  height: 45px;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.1);
}

.MBcont.mobile .splide__arrow {
  width: 25pt;
  height: 25pt;
}

.MBcont.mobile .splide__arrow--next {
  right: -12.5pt;
}

.MBcont.mobile .splide__arrow--prev {
  left: -12.5pt;
}


.splide__arrow svg {
  width: 1em;
}

.splide__arrow--next {
  right: -22px;
}

.splide__arrow--prev {
  left: -22px;
}

.splide__slide {
  margin: 10px;
}

.MBcont.mobile .splide__slide {
  margin: 3pt 1pt;
}

.slide-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #482431;
  font-size: 10pt;
  /* border: 1px solid #e5e5e5; */
  background-color: white;
  border-radius: 5px;
  text-align: left;
  padding: 25px 30px;
  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
  box-sizing: border-box;
  height: 100%;
}

.MBcont.mobile .slide-item {
  padding: 10pt 10pt;
  font-size: 9pt;
}

.slide-item:hover {
  transform: scale(1.05);
  background-color: #FFD2CA;
  /* border: 1px solid white; */
}

.slide-item.selected {
  background-color: #fff0ee;
  border: 1px solid white;
}

.slide-h {
  font-size: 16pt;
  line-height: 1;
  margin-bottom: 10px;
  margin-top: 5px;
}

.slide-h.mobile {
  font-size: 11pt;
  font-weight: 600;
}

.slide-look {
  display: block;
}

.MBcont {
  max-width: 900px;
  margin: 0 auto;
  border-top: 1px solid #e6e6e6;
  padding: 2em;
  min-height: 300px;
  position: relative;
}

.MBcont {
  padding: 1em;
}

.MBcont h1 {
  font-size: 35pt;
  line-height: 45pt;
  text-align: center;
  margin: 0 0 20px 0;
  font-weight: normal;
}

.MBcont.mobile h1 {
  font-size: 24pt;
  line-height: 1;
  margin: 0 0 10pt 0;
}

.MBcont .subtitle {
  position: relative;
  top: -25px;
  font-size: 10pt;
}

.MBtextcont {
  max-width: 600px;
  margin: 0 auto 15pt auto;
  font-size: 12pt;
  line-height: 22pt;
}

.MBtextcont h1 {
  margin-top: 20pt;
}

.MBtextcont h2 {
  font: 600 16pt/20pt "Montserrat", arial, sans-serif;
  text-align: left;
  margin: 0pt 0 15pt 0;
}

.MBcont.mobile .MBtextcont h2 {
  margin: 20pt 0 10pt 0;
  font-size: 14pt;
  line-height: 1.2;
}

.MBcont.mobile .MBtextcont p {
  margin-bottom: 8pt;
}

.MBcont.mobile .MBtextcont {
  line-height: 1.7;
  font-size: 11pt;
}

.MBcont.mobile .MBvideo {
  margin-bottom: 20pt;
}


.MBtextcont ul {
  list-style: disc;
}

.MBtextcont ol {
  list-style: decimal;
}

.MBtextcont ol ol {
  list-style: lower-roman;
  margin-bottom: 0;
}

.MBtextcont ul,
.MBtextcont ol {
  margin: 0 0 2em 0;
  padding-left: 25pt;
}

.MBtextcont ul li,
.MBtextcont ol li {
  margin-bottom: 0.5em;
}

.MBtextcont p {
  margin-bottom: 15pt;
  margin-top: 0;
}

.MBtextcont b {
  font-weight: 600;
}

.MBtextcont p.nomargin {
  margin: 0pt;
}

.MBback {
  position: absolute;
  top: 0;
  left: 0;
}

/* .MBtextcont .a-download {
        padding: 5px 5px 5px 25px;
        background: url('/img/membersh/MB-icons.svg') no-repeat;
        background-size: 20px;
        background-position: 2px -75px;
    } */

.MBtextcont h3 {
  font: 600 14pt/20pt "Montserrat", arial, sans-serif;
  text-align: left;
  margin: 0 0 1em 0;
}

.MBtextcont h3.nomargin {
  margin-bottom: 0.5em;
}

.MBtextcont a::after {
  content: "";
  border-top: 1px solid #b23642;
  position: absolute;
  width: 100%;
  left: 0;
  right: 100%;
  bottom: -3px;
  transition: width 0.4s ease-out;
}

.MBtextcont a:hover::after {
  width: 0;
}

.KImage {
  margin: 0 auto 50px auto;
}

.small {
  background-color: #eeeeee88;
  padding: 2em;
  font-size: 0.9em;
  line-height: 1.5em;
  margin-bottom: 2em !important;
}

a.download-bt {
  width: 100%;
  display: block;
  background: #C26251;
  text-align: center;
  color: white;
  padding: 0.7em 0;
  border-radius: 30px;
  margin-bottom: 1em;
  line-height: 1.3em;
}

a.download-bt::after {
  border: 0px solid transparent;
}

.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

a.download-bt.half {
  width: 45%;
  box-sizing: border-box;
  padding: 0.7em 1em;
}

.MBpay.mobile .button-block {
  flex-wrap: wrap;
  flex-direction: column;
}

.MBpay.mobile a.download-bt.half {
  width: 100%;
}

.MBpay.mobile a.download-bt {
  margin-bottom: 0;
}
</style>